import type { ApiV2ContextualStrateOffer } from '@dce-front/hodor-types/api/v2/contextual_offer_page/definitions';
import classNames from 'classnames';
import type { JSX } from 'react';
import { useTranslation } from '../../../../../lang';
import type { OnClickHodorType } from '../../../../../templates/FunnelTvod/helpers/types';
import { MajorOffer } from './MajorOffer/MajorOffer';
import styles from './MajorOffers.css';

type MajorOffersProps = {
  /** custom className */
  className?: string;

  /** list of majors */
  majorOffers: ApiV2ContextualStrateOffer[];

  /** function triggered when user clicking on the component */
  onClick: ({ onClick }: OnClickHodorType) => void;
};

/** This component is used to display all MajorOfferItems */
export function MajorOffers({
  className,
  majorOffers,
  onClick,
}: MajorOffersProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.majorOffers, className)}
      data-testid="major-offers"
    >
      {majorOffers.map(
        (
          { onClick: onClickHodor, prices, subtitle, title, shortTitle },
          index
        ) => {
          const pricesLabel = prices?.promotionalPriceLabel
            ? `${prices?.promotionalPriceLabel} ${t('FunnelTvod.insteadOf')} ${prices?.initialPriceLabel}`
            : prices?.initialPriceLabel;

          return (
            <MajorOffer
              shortTitle={shortTitle}
              // do not use "mapStaticKey" here to avoid rerender each time the hash is changed,
              // use "index" instead, until "mapStaticKey" is refactored
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              prices={prices}
              onClick={() => onClick({ onClick: onClickHodor })}
              subtitle={subtitle}
              hasClickOnCard={$_BUILD_RENDERMODE_CSR}
              title={title}
              ariaLabelPrices={pricesLabel}
            />
          );
        }
      )}
    </div>
  );
}
