import { loginUser } from '@canalplus/ifc-onecore';
import { isClientSide } from '@canalplus/mycanal-commons';
import { getSigninUrl } from '@canalplus/sdk-pass';
import { usePassSdk } from '@canalplus/sdk-pass-react';
import type { HandleStepParams } from '../../ContextualOffer';

/**
 * Hook that redirectTo to account and redirect to urlPage from template PaymentMeans
 * @returns {Function} onClick
 */
export const useRedirectProspect = (): ((
  onClick: HandleStepParams['onClick']
) => void) => {
  const signinUrl = usePassSdk(getSigninUrl);

  return ({ path }: HandleStepParams['onClick'] = {}) => {
    const redirectUri = isClientSide()
      ? `${window.location.origin}${path}`
      : '';

    if ($_BUILD_RENDERMODE_CSR) {
      loginUser();
      return;
    }

    window.location.assign(signinUrl({ redirectUri }));
  };
};
