import { AlertStatus, Button, MaterialChevronRightSvg } from '@canalplus/dive';
import { castToEnum } from '@canalplus/mycanal-commons';
import { Binder } from '@canalplus/one-navigation';
import type { ApiV2PaymentMeans } from '@dce-front/hodor-types';
import type { JSX, SyntheticEvent } from 'react';
import { useCallback, useState } from 'react';
import Alert from '../../../../components/Alert/Alert';
import { FocusManager } from '../../../../helpers/oneNavigation/FocusManager';
import { MIDDLEWARE_FUNNEL_VOD } from '../../../../helpers/oneNavigation/middleware';
import { useTranslation } from '../../../../lang';
import { FunnelInnerStep } from '../../stores/constants';
import { useFunnelCurrentStep } from '../../stores/funnel/hooks';
import { OfferHeader } from '../ContextualOffer/OfferHeader/OfferHeader';
import { TermsOfSale } from '../PaymentMeans/TermsOfSale/TermsOfSale';
import { TvodTitle } from '../TvodTitle/TvodTitle';
import styles from './TvodForm.css';

type RenderContentParams = {
  /**
   * The form's submit button was pressed at least once
   */
  isFormSubmitted: boolean;
  /**
   * To change form state submission
   */
  setIsFormSubmitted: (isFormSubmitted: boolean) => void;
  /**
   * Function to be called when all the items are valid
   */
  setIsFormValid: (isFormValidated: boolean) => void;
};

export type TvodFormProps = {
  /**
   * The form's title
   */
  title: string;
  /**
   * The form's data
   */
  data: ApiV2PaymentMeans;
  /**
   * If true, the form's footer will not be displayed (button plus footer description)
   */
  noFooter?: boolean;
  /**
   * Function to be called when click button close
   */
  onClose?: () => void;
  /**
   * Function to be called when all the items are valid
   */
  onSubmit?: () => void;
  /**
   * Function to be called when the terms of sale button is clicked
   */
  onClickTOS?: () => void;
  /**
   * Inner content of the form
   */
  renderContent: (params: RenderContentParams) => JSX.Element;
  /**
   * Callback focus binder
   */
  focusManager?: FocusManager;
};

export function TvodForm({
  focusManager,
  data,
  title,
  noFooter,
  renderContent,
  onClose,
  onSubmit,
  onClickTOS,
}: TvodFormProps): JSX.Element {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const { t } = useTranslation();
  const currentStep = useFunnelCurrentStep();

  const isTermsOfSale = currentStep.innerStep === FunnelInnerStep.TermsOfSale;

  // Only disable the button if the form has been submitted, is not valid and we are not on TV
  const isBtnDisabled =
    isFormSubmitted && !isFormValid && !$_BUILD_RENDERMODE_CSR;

  const { detail, alertBox, button } = data || {};
  const {
    URLImage,
    securedPayment,
    title: programTitle,
    subtitle,
    prices,
    technicalInfos,
    footerDescription,
  } = detail || {};
  const { promotion, promotionalPrice } = detail?.prices || {};
  const isFree = promotion && promotionalPrice === 0;
  const technicalInfosA11y = {
    closedCaptioningText: t('Accessibility.closedCaptioning'),
    audioDescriptionText: t('Accessibility.audioDescription'),
  };
  const castToAlertStatus = castToEnum(AlertStatus);

  const handleSubmit = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      setIsFormSubmitted(true);
      if (isFormValid) {
        onSubmit?.();
      }
    },
    [isFormValid, onSubmit, setIsFormSubmitted]
  );
  if (alertBox?.state === AlertStatus.Error) {
    return (
      <form className={styles.TvodForm}>
        <TvodTitle
          className={styles.TvodForm__title}
          title={title}
          subtitle={securedPayment?.label}
          hasIcon={!!securedPayment?.displayPicto}
        />
        <OfferHeader
          className={styles.TvodForm__offerHeader}
          key="TvodForm_offerHeader"
          URLImage={URLImage}
          title={programTitle || ''}
          subtitle={subtitle || ''}
          prices={prices}
          technicalInfos={technicalInfos}
          technicalInfosA11y={technicalInfosA11y}
        />
        {!!alertBox?.label && (
          <Alert
            className={styles.TvodForm__alertBox}
            status={castToAlertStatus(alertBox.state)}
            message={alertBox.label}
          />
        )}
        <Binder middleware={MIDDLEWARE_FUNNEL_VOD} forceFocusOnMount>
          <Button variant="primary" onClick={onClose} width="fixed">
            {t('FunnelTvod.close')}
          </Button>
        </Binder>
      </form>
    );
  }

  if (isTermsOfSale) {
    return <TermsOfSale focusManager={focusManager} />;
  }

  return (
    <form className={styles.TvodForm}>
      <TvodTitle
        className={styles.TvodForm__title}
        title={title}
        subtitle={securedPayment?.label}
        hasIcon={!!securedPayment?.displayPicto}
      />
      <Binder middleware={MIDDLEWARE_FUNNEL_VOD}>
        <OfferHeader
          className={styles.TvodForm__offerHeader}
          key="TvodForm_offerHeader"
          URLImage={URLImage}
          title={programTitle || ''}
          subtitle={subtitle || ''}
          prices={prices}
          technicalInfos={technicalInfos}
          technicalInfosA11y={technicalInfosA11y}
        />
        {!!alertBox?.label && (
          <Alert
            className={styles.TvodForm__alertBox}
            status={castToAlertStatus(alertBox.state)}
            message={alertBox.label}
          />
        )}
        {renderContent({ isFormSubmitted, setIsFormSubmitted, setIsFormValid })}
        {button && !noFooter && (
          <>
            <Button
              onClick={handleSubmit}
              type="submit"
              width={isFree ? 'fit' : 'fixed'}
              disabled={isBtnDisabled}
            >
              {button.label}
            </Button>
            {footerDescription && (
              <div className={styles.TvodForm__footerDescription}>
                {footerDescription}
              </div>
            )}
            {$_BUILD_RENDERMODE_CSR && (
              <Button
                font="hind"
                variant="text"
                icon={<MaterialChevronRightSvg />}
                onClick={onClickTOS}
              >
                {t('FunnelTvod.cgvLabel')}
              </Button>
            )}
          </>
        )}
      </Binder>
    </form>
  );
}
