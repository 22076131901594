import { AlertStatus, Button } from '@canalplus/dive';
import { castToEnum, fillTemplate } from '@canalplus/mycanal-commons';
import {
  VirtualKeyboardProvider,
  getDefaultSeasonIndex,
} from '@canalplus/mycanal-sharedcomponent';
import { mapStaticKey } from '@canalplus/mycanal-util-react';
import { Binder, useLayer } from '@canalplus/one-navigation';
import type { ApiV2ContextualOfferPage } from '@dce-front/hodor-types';
import type { ApiV2OnClick } from '@dce-front/hodor-types/api/v2/common/dto/definitions';
import type { ApiV2ContextualStrateOffer } from '@dce-front/hodor-types/api/v2/contextual_offer_page/definitions';
import type { ApiV2MoreInfosSelector } from '@dce-front/hodor-types/api/v2/more_infos/definitions';
import type { JSX } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Alert from '../../../../components/Alert/Alert';
import {
  useAppHistory,
  useAppLocation,
} from '../../../../helpers/hooks/reactRouter';
import { FocusManager } from '../../../../helpers/oneNavigation/FocusManager';
import {
  MIDDLEWARE_FUNNEL_VOD,
  MIDDLEWARE_FUNNEL_VOD_SEASONS_TABS,
} from '../../../../helpers/oneNavigation/middleware';
import { useTranslation } from '../../../../lang';
import { isImmersiveSelector } from '../../../../store/slices/immersive-selectors';
import { authenticatedSelector } from '../../../../store/slices/user-selectors';
import { SeasonsTabsContainer } from '../../../../templates/DetailV5/components/EpisodesList/components/SeasonsTabsContainer/SeasonsTabsContainer';
import type { FunnelHodorStep } from '../../stores/constants';
import { FunnelInnerStep } from '../../stores/constants';
import {
  amendFunnelHistory,
  decrementFunnelHistory,
  incrementFunnelHistory,
  resetPaymentMean,
  setCurrentStep,
} from '../../stores/funnel/actions';
import {
  useFunnelCurrentStep,
  useFunnelDispatch,
} from '../../stores/funnel/hooks';
import { TvodTitle } from '../TvodTitle/TvodTitle';
import { VoucherDetail } from '../Voucher/VoucherDetail';
import { VoucherEntryBar } from '../Voucher/VoucherEntryBar';
import { VoucherStep } from '../Voucher/VoucherStep';
import styles from './ContextualOffer.css';
import { MajorOffers } from './MajorOffers/MajorOffers';
import { MinorOffers } from './MinorOffers/MinorOffers';
import { OfferHeader } from './OfferHeader/OfferHeader';
import { useRedirectProspect } from './hooks/useRedirectProspect/useRedirectProspect';

const BINDER_ID_CONTEXTUAL_BODY = 'contextual-offer-body';

export type HandleStepParams = {
  /** onClick data Hodor */
  onClick: ApiV2ContextualStrateOffer['onClick'];
  /** Is episode boolean */
  isEpisode?: boolean;
};

type ContextualOfferProps = {
  /** Data from Hodor */
  data?: ApiV2ContextualOfferPage;
  focusManager?: FocusManager;
};

/**
 * ContextualOffer component
 */
export function ContextualOffer({
  data,
  focusManager,
}: ContextualOfferProps): JSX.Element {
  const { t } = useTranslation();
  const isAuthenticated = useSelector(authenticatedSelector);
  const isImmersive = useSelector(isImmersiveSelector);
  const { pathname } = useAppLocation();
  const history = useAppHistory();
  const layer = useLayer();
  const toAlertStatus = castToEnum(AlertStatus);

  // Hooks for funnel
  const funnelDispatch = useFunnelDispatch();
  const currentStep = useFunnelCurrentStep();
  const handleRedirectProspect = useRedirectProspect();

  // Exclusive TV step
  const isVoucherStepTv =
    $_BUILD_RENDERMODE_CSR && currentStep.innerStep === FunnelInnerStep.Voucher;

  const {
    selector: seasonsSelector,
    currentPage,
    strates,
    alertBox,
  } = data || {};
  const [firstStrate] = strates || [];
  const { voucher: firstStrateVoucher } = firstStrate || {};
  const { displayName } = currentPage || {};

  // Check if alertBox is attached to entryBar (Web only)
  const isAlertBoxAttached =
    !$_BUILD_RENDERMODE_CSR &&
    !!firstStrateVoucher?.entryBar &&
    firstStrateVoucher?.alertBox?.state === AlertStatus.Error;

  // Generate key for offers strates to refresh data call UQH
  const offersList = mapStaticKey(strates, 'type');

  // (For season context): Computes the default season index to focus and the season url
  const { activeSeasonIndex } = useMemo(
    () => getDefaultSeasonIndex(seasonsSelector || [], pathname),
    [seasonsSelector, pathname]
  );
  const activeSeason = seasonsSelector?.[activeSeasonIndex];

  useEffect(() => {
    // When we are on contextualOffer step, we reset selected PaymentMean
    funnelDispatch(resetPaymentMean());
  }, [funnelDispatch]);

  useEffect(() => {
    // Prevents losing focus from an episode unitary to a season ContextualOffer
    // and also when removing a voucher.
    if (focusManager && data) {
      focusManager.onFocusable();
      // Focus on the first focusable element (MajorOffer) if the focus was on the same binder
      // as the Major and Minor offers.
      const contextualBodyBinder = layer.getBinderById(
        BINDER_ID_CONTEXTUAL_BODY
      );
      if (layer.currentBinder === contextualBodyBinder) {
        contextualBodyBinder?.focusByIndex(0);
      }
    }
  }, [data, focusManager, layer]);

  const updateHistory = useCallback(
    (path: string) => {
      const context = isImmersive ? 'immersive' : 'page';
      history.replace(path, {
        ...history.location.state,
        [context]: {
          ...history.location.state?.[context],
          mainOnClick: {
            ...history.location.state?.[context]?.mainOnClick,
            path,
          },
        },
      });
    },
    [history, isImmersive]
  );

  const handleSeasonChange = useCallback(
    (onClick: ApiV2OnClick | ApiV2MoreInfosSelector['onClick']) => {
      const { URLPage, displayTemplate, path } = onClick || {};
      const newStep = {
        hodorStep: displayTemplate as FunnelHodorStep,
        url: URLPage,
      };
      funnelDispatch(setCurrentStep(newStep));
      funnelDispatch(amendFunnelHistory(newStep));

      if (path) {
        updateHistory(path);
      }
    },
    [funnelDispatch, updateHistory]
  );

  // Button step handler
  const handleStep = useCallback(
    ({ onClick, isEpisode }: HandleStepParams) => {
      if (!isAuthenticated && !isEpisode) {
        handleRedirectProspect(onClick);
      } else {
        const { URLPage, displayTemplate } = onClick || {};
        const newStep = {
          url: URLPage,
          hodorStep: displayTemplate as FunnelHodorStep,
        };
        funnelDispatch(setCurrentStep(newStep));
        funnelDispatch(incrementFunnelHistory(newStep));
      }
    },
    [isAuthenticated, funnelDispatch, handleRedirectProspect]
  );

  // Handler voucher add (TV exclusive)
  const handleAddVoucher = useCallback(() => {
    // Open Voucher inner step (TV)
    const newStep = { ...currentStep, innerStep: FunnelInnerStep.Voucher };
    funnelDispatch(setCurrentStep(newStep));
    funnelDispatch(incrementFunnelHistory(newStep));
  }, [currentStep, funnelDispatch]);

  // Handler voucher submit
  const handleSubmitVoucher = useCallback(
    (inputVoucher: string) => {
      const { URLPage, displayTemplate } =
        firstStrateVoucher?.entryBar?.action?.onClick || {};
      if (inputVoucher && URLPage && displayTemplate) {
        const url = decodeURIComponent(URLPage);
        const newStep = {
          url: fillTemplate(url, [['voucherKey', inputVoucher]]),
          hodorStep: displayTemplate as FunnelHodorStep,
        };
        // We don't want to amend the innerStep Voucher, but rather the previous step ContextualOffer
        if (isVoucherStepTv) {
          funnelDispatch(decrementFunnelHistory());
        }
        funnelDispatch(amendFunnelHistory(newStep));
        funnelDispatch(setCurrentStep(newStep));
      }
    },
    [funnelDispatch, firstStrateVoucher, isVoucherStepTv]
  );

  // Handler voucher delete
  const handleDeleteVoucher = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement>) => {
      const { title: currentVoucherKey, action } =
        firstStrateVoucher?.detail || {};
      const { URLPage, displayTemplate, path } = action?.onClick || {};
      if (URLPage && displayTemplate && currentVoucherKey && path) {
        const url = decodeURIComponent(URLPage);
        const newStep = {
          url,
          hodorStep: displayTemplate as FunnelHodorStep,
        };
        funnelDispatch(amendFunnelHistory(newStep));
        funnelDispatch(setCurrentStep(newStep));
        updateHistory(path);
      }
    },
    [firstStrateVoucher?.detail, funnelDispatch, updateHistory]
  );

  if (isVoucherStepTv && firstStrateVoucher?.entryBar) {
    return (
      <VirtualKeyboardProvider>
        <VoucherStep
          entryBar={firstStrateVoucher?.entryBar}
          onSubmit={handleSubmitVoucher}
          focusManager={focusManager}
        />
      </VirtualKeyboardProvider>
    );
  }

  return (
    <div className={styles.contextualOffer}>
      {displayName && (
        <TvodTitle
          className={styles.contextualOffer__title}
          title={displayName}
        />
      )}
      {alertBox && (
        <Alert
          className={styles.contextualOffer__alertBox}
          status={toAlertStatus(alertBox.state)}
          message={alertBox.label ?? ''}
        />
      )}
      {offersList.map(
        ({
          URLLogoTitle,
          altLogoTitle,
          title,
          subtitle,
          majorOffers,
          minorOffers,
          hash,
          voucher,
          button,
        }) => (
          <div key={hash}>
            {activeSeason && (
              <Binder middleware={MIDDLEWARE_FUNNEL_VOD_SEASONS_TABS}>
                <SeasonsTabsContainer
                  handleClick={handleSeasonChange}
                  seasons={seasonsSelector}
                  itemId="episodesList-season-item"
                  className={styles.contextualOffer__seasonsTabs}
                />
              </Binder>
            )}
            <Binder
              binderId={BINDER_ID_CONTEXTUAL_BODY}
              middleware={MIDDLEWARE_FUNNEL_VOD}
              forceFocusOnMount
            >
              <OfferHeader
                className={styles.contextualOffer__offerHeader}
                key="offerHeader"
                logo={{
                  URLLogoTitle: URLLogoTitle || '',
                  altLogoTitle: altLogoTitle || '',
                }}
                title={title || ''}
                subtitle={subtitle || ''}
              />
              {majorOffers && (
                <MajorOffers
                  className={styles.contextualOffer__majorOffers}
                  majorOffers={majorOffers}
                  onClick={handleStep}
                />
              )}
              {minorOffers && (
                <MinorOffers
                  className={styles.contextualOffer__minorOffers}
                  minorOffers={minorOffers}
                  onClick={handleStep}
                />
              )}

              {voucher && (
                <>
                  <div className={styles.contextualOffer__voucher}>
                    {voucher.detail && (
                      <VoucherDetail
                        detail={voucher.detail}
                        onDelete={handleDeleteVoucher}
                      />
                    )}
                    {voucher.alertBox?.label && !isAlertBoxAttached && (
                      <Alert
                        className={styles.contextualOffer__alertBox}
                        status={toAlertStatus(voucher.alertBox.state)}
                        message={voucher.alertBox.label}
                      />
                    )}
                    {voucher.entryBar && !$_BUILD_RENDERMODE_CSR && (
                      <VoucherEntryBar
                        alertBox={
                          isAlertBoxAttached ? voucher?.alertBox : undefined
                        }
                        entryBar={voucher.entryBar}
                        onSubmit={handleSubmitVoucher}
                        title={`${t('FunnelTvod.promotionalCode')}`}
                      />
                    )}
                  </div>
                  {$_BUILD_RENDERMODE_CSR && voucher.entryBar && (
                    <Button
                      variant="tertiary"
                      width="fit"
                      onClick={handleAddVoucher}
                      font="hind"
                    >
                      {t('FunnelTvod.addPromotionalCode')}
                    </Button>
                  )}
                </>
              )}

              {button?.onClick && (
                <Button
                  variant="tertiary"
                  className={styles.contextualOffer__button}
                  onClick={() =>
                    handleStep({ onClick: button?.onClick, isEpisode: true })
                  }
                >
                  {button.label}
                </Button>
              )}
            </Binder>
          </div>
        )
      )}
    </div>
  );
}
