import type { HTMLAttributes, InputHTMLAttributes } from 'react';

export enum LabelArrangementOrientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export type SelectionControlProps = {
  /** Unique identifier for the control. */
  id?: HTMLAttributes<HTMLInputElement>['id'];
  /** The label displayed alongside the control. */
  label: React.ReactElement | string;
  /**
   * An optional sub-label displayed near the `label`.
   *
   * Can be positioned below or beside the label based on the `variant` prop.
   */
  subLabel?: React.ReactElement | string;
  /**
   * Specifies how the `subLabel` is arranged relative to the `label`:
   * - `'horizontal'`: Places both elements on the same line.
   * - `'vertical'`: Places the `subLabel` below the `label`.
   *
   * @default 'horizontal'
   */
  variant?: `${LabelArrangementOrientation}`;
} & InputHTMLAttributes<HTMLInputElement>;
