import type { ApiV2PaymentMeansDetail } from '@dce-front/hodor-types/api/v2/tvod/payment_means/definitions';
import { FunnelInnerStep } from '../../../../../../templates/FunnelTvod/stores/constants';
import { useFunnelCurrentStep } from '../../../../../../templates/FunnelTvod/stores/funnel/hooks';

/**
 * usePaymentMeanTitle display template name
 * Specific name is created depending of detail for oD
 * @param detail template PaymentMeans
 * @returns template title
 */
export const usePaymentMeanTitle = (
  detail?: ApiV2PaymentMeansDetail
): string => {
  const { choosePaymentMeanTitle, finalizePaymentTitle } =
    detail?.pageTitles || {};
  const currentStep = useFunnelCurrentStep();

  const isChoosePaymentStep = !currentStep.innerStep;
  const isEndPaymentStep = currentStep.innerStep === FunnelInnerStep.EndPayment;

  if (choosePaymentMeanTitle && isChoosePaymentStep && $_BUILD_RENDERMODE_CSR) {
    // On TV, always display choosePaymentMeanTitle for ChoosePayment step displayTitle = choosePaymentMeanTitle;
    return choosePaymentMeanTitle;
  } else if (
    finalizePaymentTitle &&
    (!$_BUILD_RENDERMODE_CSR || isEndPaymentStep)
  ) {
    // On desktop, always display finalizePaymentTitle for PaymentMeans step, and only for EndPayment step on TV
    return finalizePaymentTitle;
  } else {
    return '';
  }
};
