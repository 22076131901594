import { DEFAULT_SEASON_INDEX } from '@canalplus/mycanal-commons';
import {
  SeasonsTabs,
  type SeasonsTabsProps,
} from '@canalplus/mycanal-sharedcomponent';
import { Binder, enter, memory, spatial } from '@canalplus/one-navigation';
import type { ApiV2ContextualOfferSelector } from '@dce-front/hodor-types/api/v2/contextual_offer_page/definitions';
import type { ApiV2Selector } from '@dce-front/hodor-types/api/v2/episodes/definitions';
import type { ApiV2MoreInfosSelector } from '@dce-front/hodor-types/api/v2/more_infos/definitions';
import type { JSX, ReactNode } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Linker from '../../../../../../components/Linker/Linker';
import {
  alignBinderToTop,
  scroll,
  scrollFocusedIntoHorizontalList,
} from '../../../../../../helpers/oneNavigation/scroll';
import { useTranslation } from '../../../../../../lang';
import {
  pathnameSelector,
  routerOnClickSelector,
  routingContextSelector,
} from '../../../../../../store/slices/routing-selectors';

export type SeasonsTabsContainerProps = {
  /** ID for SeasonsTabs  */
  itemId: string;

  /** seasons data from hodor */
  seasons?: (
    | ApiV2Selector
    | ApiV2MoreInfosSelector
    | ApiV2ContextualOfferSelector
  )[];

  /** custom className */
  className?: string;

  /** custom onClick */
  handleClick?: SeasonsTabsProps['handleClick'];
};

export function SeasonsTabsContainer({
  itemId,
  seasons,
  className,
  handleClick,
}: SeasonsTabsContainerProps): JSX.Element | null {
  const { t } = useTranslation();
  const tabParentOnClick = useSelector(routerOnClickSelector);
  const pathname = useSelector(pathnameSelector);
  const context = useSelector(routingContextSelector);
  const defaultHodorSeasonIndex = seasons?.findIndex(
    (season) => season.default
  );

  // Generates a new one-navigation middleware each time the defaultHodorSeasonIndex or pathname changes
  const middleware = useMemo(() => {
    return [
      scroll([alignBinderToTop, scrollFocusedIntoHorizontalList()]),
      enter({
        forceFocusIndex: defaultHodorSeasonIndex || DEFAULT_SEASON_INDEX,
        shouldForceFocusOnce: true,
      }),
      memory(),
      spatial(),
    ];

    // Here we want to reset the middleware on each season change to reset memory, that's why we
    // use pathname in dependency array

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultHodorSeasonIndex, seasons]);

  const renderWrapperLinker: SeasonsTabsProps['renderWrapperLinker'] = (
    onClick,
    customClassName,
    label,
    seasonNumber,
    navigateonfocus
  ) => (
    <Linker
      title={label}
      className={customClassName}
      data={{
        mainOnClick: { ...tabParentOnClick, path: onClick?.path },
        context,
      }}
      data-navigateonfocus={navigateonfocus}
      replace
    >
      {!$_BUILD_RENDERMODE_CSR && pathname === onClick?.path && seasonNumber ? (
        <h1>{label}</h1>
      ) : (
        label
      )}
    </Linker>
  );

  const renderWrapperBinder = (children: ReactNode): JSX.Element => (
    <Binder middleware={middleware}>{children}</Binder>
  );

  return (
    <SeasonsTabs
      {...(handleClick ? { handleClick } : { renderWrapperLinker })}
      className={className}
      isTvDevice={$_BUILD_RENDERMODE_CSR}
      itemId={itemId}
      label={t('Detail.season')}
      pathname={pathname}
      renderWrapperBinder={renderWrapperBinder}
      seasons={seasons}
    />
  );
}
