import { VirtualKeyboardProvider } from '@canalplus/mycanal-sharedcomponent';
import {
  KEY_BACK,
  useKeyCatcher,
  useStore as useOneNavigationStore,
} from '@canalplus/one-navigation';
import type { TurboPlatform } from '@canalplus/sdk-core';
import { SafetyCodeContext } from '@canalplus/types-acm';
import type { ApiV2TvodTracking } from '@dce-front/hodor-types/api/v2/common/dto/tvod/defintions';
import type { ApiV2TvodPaymentState } from '@dce-front/hodor-types/api/v2/tvod/payment/definitions';
import type {
  ApiV2PaymentMeansDetail,
  ApiV2PaymentMeansPurchase,
} from '@dce-front/hodor-types/api/v2/tvod/payment_means/definitions';
import type { JSX } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { SafetyCodeInputFieldDesktop } from '../../../../components/SafetyCode/SafetyCodeDesktop/SafetyCodeInputFieldDesktop';
import SafetyCodeInputTV from '../../../../components/SafetyCode/SafetyCodeTV/SafetyCodeInputTV';
import {
  LAYER_ADULT_KEYBOARD,
  LAYER_VOD_FUNNEL,
} from '../../../../helpers/oneNavigation/layers';
import { FunnelInnerStep } from '../../stores/constants';
import {
  amendFunnelHistory,
  setCurrentStep,
} from '../../stores/funnel/actions';
import {
  useFunnelCurrentStep,
  useFunnelDispatch,
} from '../../stores/funnel/hooks';
import { usePayment } from '../hooks/usePayment/usePayment';
import styles from './PurchaseCode.css';

type PurchaseCodeProps = {
  currentContent?: ApiV2TvodPaymentState[];
  purchase?: ApiV2PaymentMeansPurchase;
  turboMedia?: TurboPlatform;
  tracking?: ApiV2TvodTracking;
  detail?: ApiV2PaymentMeansDetail;
  isFree?: boolean;
};

export function PurchaseCode({
  purchase,
  currentContent,
  turboMedia,
  tracking,
  detail,
  isFree,
}: PurchaseCodeProps): JSX.Element {
  const store = useOneNavigationStore();

  // Funnel hooks
  const currentStep = useFunnelCurrentStep();
  const funnelDispatch = useFunnelDispatch();
  const handlePay = usePayment({
    currentContent,
    purchase,
    turboMedia,
    tracking,
    detail,
    isFree,
  });

  // Set active layer to Adult Keyboard on TV device
  useEffect(() => {
    if ($_BUILD_RENDERMODE_CSR) {
      store.setActiveLayer(LAYER_ADULT_KEYBOARD);
      store.focusDefault();
    }

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handler for the back button on TV device on PurchaseCode InnerStep
  const handleBackTv = useCallback(() => {
    const newStep = { ...currentStep, innerStep: FunnelInnerStep.EndPayment };
    funnelDispatch(amendFunnelHistory(newStep));
    funnelDispatch(setCurrentStep(newStep));
    store.setActiveLayer(LAYER_VOD_FUNNEL);
    store.focusDefault();

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, funnelDispatch]);

  const handleSuccessPurchaseCode = async (validationToken?: string) => {
    setIsOnPaymentProcess(true);

    if (validationToken) {
      await handlePay(validationToken);
    }
  };

  useKeyCatcher(KEY_BACK, handleBackTv, LAYER_ADULT_KEYBOARD);

  // Hide purchase code input purposely to avoid empty input on success
  const [isOnPaymentProcess, setIsOnPaymentProcess] = useState(false);

  const SafetyCodeInput = $_BUILD_RENDERMODE_CSR ? (
    <VirtualKeyboardProvider>
      <SafetyCodeInputTV
        purchaseId={purchase?.purchaseId}
        safetyCodeContext={SafetyCodeContext.Purchase}
        onSuccess={handleSuccessPurchaseCode}
      />
    </VirtualKeyboardProvider>
  ) : (
    <SafetyCodeInputFieldDesktop
      safetyCodeContext={SafetyCodeContext.Purchase}
      purchaseId={purchase?.purchaseId}
      onSuccess={handleSuccessPurchaseCode}
    />
  );

  return (
    <div className={styles.PurchaseCode}>
      {!isOnPaymentProcess && SafetyCodeInput}
    </div>
  );
}
