import type { JSX } from 'react';
import { useId } from 'react';
import { StatusCheckSvg } from '../../../icons/icons';
import {
  checkboxCheckIndicatorCVA,
  checkboxInputCVA,
  checkboxLabelContentCVA,
  checkboxLabelWrapper,
  checkboxSubLabelCVA,
  checkboxWrapperCVA,
} from './Checkbox.cva';
import type { CheckboxProps } from './Checkbox.types';

/**
 * A Checkbox allows users to select multiple options from a set.
 *
 * @example
 *
 * ```tsx
 * <Checkbox
 *    label="Option 1"
 *    subLabel="A sub-label for option 1"
 *    value="1"
 *    checked={isChecked}
 *    onChange={handleChange}
 * />
 * ```
 */
export function Checkbox({
  id,
  label,
  subLabel,
  variant = 'horizontal',
  status = 'default',
  className,
  disabled,
  checked,
  'data-testid': dataTestId,
  ...props
}: CheckboxProps): JSX.Element {
  const uniqueId = useId();
  const inputId = id || uniqueId;

  return (
    <div
      data-testid={dataTestId}
      className={checkboxWrapperCVA({ disabled, className })}
    >
      <div className="relative self-start min-w-22 size-22 box-border mr-dt-spacing-200">
        <input
          type="checkbox"
          id={inputId}
          disabled={disabled}
          checked={checked}
          className={checkboxInputCVA({
            checked,
            disabled,
            hasError: status === 'error',
          })}
          {...props}
        />
        {checked && (
          <StatusCheckSvg className={checkboxCheckIndicatorCVA({ disabled })} />
        )}
      </div>
      <div className={checkboxLabelWrapper({ variant })}>
        <label
          htmlFor={inputId}
          className={checkboxLabelContentCVA({
            variant,
            disabled,
          })}
        >
          <span className="text-dt-theme-text-checkbox-checkbox-label mr-dt-spacing-100">
            {label}
          </span>
          {subLabel && (
            <span className={checkboxSubLabelCVA({ variant })}>{subLabel}</span>
          )}
        </label>
      </div>
    </div>
  );
}
