import classNames from 'classnames';
import type { JSX } from 'react';
import { useId } from 'react';
import {
  MaterialRadioCheckedSvg,
  MaterialRadioUncheckedSvg,
} from '../../../icons/icons';
import {
  radioActionableElementCVA,
  radioCheckIndicatorCVA,
  radioInputCVA,
  radioLabelContentCVA,
  radioLabelWrapperCVA,
  radioSubLabelCVA,
  radioWrapperCVA,
} from './Radio.cva';
import type { RadioProps } from './Radio.types';

/**
 * A Radio component allows users to select one option from a set.
 *
 * @example
 *
 * ```tsx
 * <Radio
 *    label="Option 1"
 *    name="option" value="1"
 *    checked={true}
 *    onChange={handleChange}
 *    disabled={false}
 *    isFormValidated={false}
 *    required={false}
 * />
 * ```
 */
export function Radio({
  id,
  label,
  subLabel,
  name,
  value,
  checked,
  disabled,
  required,
  actionableElement,
  isError,
  variant = 'horizontal',
  onChange,
  className,
  'data-testid': dataTestId,
}: RadioProps): JSX.Element {
  const radioUniqueId = useId();
  const inputId = id || radioUniqueId;

  return (
    <div
      data-testid={dataTestId}
      className={radioWrapperCVA({ disabled, className })}
    >
      <div
        className={classNames(
          'relative self-start min-w-22 size-22 box-border mr-dt-spacing-200'
        )}
      >
        <input
          id={inputId}
          type="radio"
          aria-checked={checked}
          name={name}
          checked={checked}
          value={value}
          disabled={disabled}
          aria-disabled={disabled}
          onChange={onChange}
          required={required}
          tabIndex={-1}
          className={radioInputCVA({ disabled })}
        />
        {!checked && (
          <MaterialRadioUncheckedSvg
            className={radioCheckIndicatorCVA({
              disabled,
              isError,
              checked,
            })}
            aria-hidden
          />
        )}
        {checked && (
          <MaterialRadioCheckedSvg
            className={radioCheckIndicatorCVA({
              disabled,
              isError,
              checked,
            })}
            aria-hidden
          />
        )}
      </div>
      <div className={radioLabelWrapperCVA({ variant })}>
        <label
          htmlFor={inputId}
          className={radioLabelContentCVA({ variant, disabled })}
        >
          {/* Label */}
          {label && (
            <span className="text-dt-theme-text-radio-button-radio-label mr-dt-spacing-100">
              {label}
            </span>
          )}

          {/* SubLabel */}
          {subLabel && (
            <span className={radioSubLabelCVA({ variant })}>{subLabel}</span>
          )}
        </label>
        {actionableElement && checked && (
          <div className={radioActionableElementCVA({ variant })}>
            {actionableElement}
          </div>
        )}
      </div>
    </div>
  );
}
